/** @jsx jsx */
import { jsx } from 'theme-ui'

export default props => (
  <div sx={{
    maxWidth: props.mw,
    mx: 'auto',
  }}>
    {props.children}
  </div>
)