/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "theme-ui"

export default props => (
  <div>
    <hr 
      sx={{ 
        height: 16, 
        bg: "text", 
        my: 0, 
        }} />
  </div>
)