/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import background from "../images/footer-scooter-sharkfin.png"
import roundel from "../images/roundel.png"
import Contain from "../components/contain"
import Btn from "../components/button"

const FooterLink = (props) => (
  <Link
    sx={{
      textTransform: "uppercase",
      fontFamily: "sans",
      fontSize: [1,0],
      color: "white",
      textDecoration: 'none',
      '&:hover': {
        color: 'contiYellow',
      }
    }}
    to={props.to}
  >
    {props.children}
  </Link>
)

export default (props) => (
  <footer
    sx={{
      mt: 5,
      position: "relative",
    }}
  >
    <Contain mw={"col12"}>
      <img  sx={{ width: ["95%", "60%", "60%"] }} 
            src={background}
            alt="Scooter imagery" />

      <div
        sx={{
          bg: "#222",
          display: "flex",
          flexDirection: ["column", "row"],
          px: 4,
          py: 5,
          // flex: 1,
          color: "white",
          alignItems: "center",
          fontSize: 0,
          position: "relative",
        }}
      >
        {/* Roundel Unit */}
        <div
          sx={{
            order: [2,1],
            mt: [5, 0],
            flex: 1,
          }}
        >
          <div
            sx={{
              display: "flex",
              flexDirection: 'row',
              alignItems: "center",
            }}
          >
            <img  src={roundel} 
                  sx={{ width: 50, pr: 2 }} 
                  alt="ModSquad"
                  />
            <div>
            <a
              target="_blank"
              href="https://modsquad.com/privacy-and-cookies/"
              sx={{
                fontFamily: "sans",
                fontWeight: "300",
                textDecoration: "none",
                color: "ivory",
                display: 'block',
                '&:hover': {
                  color: 'contiYellow',
               }
              }}
              to={"/"}
            >
              Privacy &amp; Cookies
            </a>
            <p sx={{
              fontSize: 12,
              color: '#999',
              fontFamily: 'sans',
              fontWeight: '300',
              display: 'block',
              m: 0,
              p: 0,
            }}>©{new Date().getFullYear()} ModSquad</p>
            </div>
          </div>
        </div>
        
        {/* Footer Nav */}
        <div
          sx={{
            display: "flex",
            order: [1,2],
            alignItems: "center",
            flexDirection: ["column", "row"],
            justifyContent: "flex-end",
            gap: 3,
          }}
        >
          <FooterLink to="/">The Platform</FooterLink>
          <FooterLink to="/manifesto">The Manifesto</FooterLink>
          <Btn/>
        </div>

      </div>
    </Contain>
  </footer>
)
