/** @jsx jsx */
import { jsx, Button } from 'theme-ui'

const Btn = () => (
  <a  target="_blank" 
      rel="noreferrer"
      href="https://modsquad.com/connect/?frm=cubeless">
    <Button sx={{ 
      bg: 'text',
      fontSize: [1,0],
    }}>
      Contact ModSquad
    </Button>
</a>
)

export default Btn
