/** @jsxRuntime classic */
/** @jsx jsx */
import { useState } from "react"
import { Link } from "gatsby"
import { jsx } from "theme-ui"
// import Logo from "../components/logo"
// import msqlogo from "../images/msq-logo.png"
import Logo from "../components/logo-by-msq"
import SEO from "./seo"
import Footer from "../components/footer"
import "../css/mobile-nav.css"
import Btn from "../components/button"

const NavLink = (props) => (
  <Link
    sx={{
      textTransform: "uppercase",
      fontFamily: "sans",
      fontSize: 0,
      color: "text",
      textDecoration: 'none',
      px: 3,
      py: 2,
      '&:hover': {
        bg: 'contiYellow',
      }
    }}
    to={props.to}
  >
    {props.children}
  </Link>
)

const NavA = (props) => (
  <a
    sx={{
      textTransform: "uppercase",
      fontFamily: "sans",
      fontSize: 0,
      color: "text",
      textDecoration: 'none',
      px: 3,
      py: 2,
      '&:hover': {
        bg: 'contiYellow',
      }
    }}
    href={props.to}
  >
    {props.children}
  </a>
)

const MobileNavLink = (props) => (
  <li sx={{py: 2}}>
    <Link
      sx={{
        fontFamily: "body",
        fontSize: 3,
        color: "white",
        textDecoration: 'none',
      }}
      to={props.to}
    >
      {props.children}
    </Link>
  </li>
)

const MobileNavA = (props) => (
  <li sx={{py: 2}}>
    <a
      sx={{
        fontFamily: "body",
        fontSize: 3,
        color: "white",
        textDecoration: 'none',
      }}
      href={props.to}
    >
      {props.children}
    </a>
  </li>
)

const Layout = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div>
     {/* Mobile Menu Overlay*/}
     <div className={mobileMenuOpen ? "overlay show" : "overlay"}>
        <div className="wrap">
          <nav
            sx={{
              maxWidth: 500,
              mx: "auto",
              mt: 160,
              textAlign: "center",
            }}
          >
            <ul sx={{ listStyle: "none", margin: 0, padding: 0 }}>
              <MobileNavLink to="/manifesto">The Manifesto</MobileNavLink>
              <MobileNavLink to="/">The Platform</MobileNavLink>
              <MobileNavA to="https://modsquad.com">ModSquad.com</MobileNavA>
            </ul>
            <div sx={{mt: 4}}>
            <Btn/>
            </div>
          </nav>
        </div>
      </div>
    <div sx={{
        px: [3,4,5,0],
        py: [3,0],
      }}>
      <SEO />

     
      <div sx={{
        maxWidth: 'col12',
        mx: 'auto',
      }}>
      
        <header
          sx={{
            pt: [1,4],
            pb: 2,
            display: "flex",
            flex: "justify-between",
            alignItems: "center",
          }}
        >
          <div
            sx={{
              flex: 1,
            }}
          >
            <div sx={{}}>
              <Link to={"/"}>
                {/* <img src={ msqlogo } 
                    sx={{
                      width: 140,
                    }}
                    alt="ModSquad Logo"
                />*/}
                <Logo width="200"/>
              </Link> 
            </div>
          </div>
          {/* All right menu items */}
          <div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Desktop Nav Group */}
            <div
              sx={{
                display: ["none", "none", "inherit"],
                alignItems: "center",
              }}
            >
              <div sx={{ mr: 3 }}>
                <NavLink to="/">The Platform</NavLink>
                <NavLink to="/manifesto">The Manifesto</NavLink>
                <NavA to="https://modsquad.com">ModSquad.com</NavA>
              </div>
              <Btn/>
            </div>
            {/* hamburger */}
            <div id="hamburger" sx={{ 
               display: ['block', 'block', 'none'],
               ml: [0,4],
               mr: [1,0],
               }} 
               onClick={() => setMobileMenuOpen(!mobileMenuOpen)} 
               className={mobileMenuOpen ? "open" : ""}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </header>
      </div>

      <main sx={{ mt: [4,5] }}>{props.children}</main>
      <Footer />
    </div>
    </div>
  )
}

export default Layout
