/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsx jsx **/
import { jsx } from "theme-ui";

export default (props) => (
<svg
  {...props}
  width={props.width}
  // width="320" 
  height="93" 
  viewBox="0 0 320 93" 
  fill="none" xmlns="http://www.w3.org/2000/svg">
{/* <rect width="320" height="93" fill="white"/> */}

<path d="M75.7876 45.9638C75.7876 62.8538 62.3976 76.1238 45.3576 76.1238C28.9476 76.1238 15.4575 62.5638 15.4375 46.0338C15.4075 29.1538 28.9476 15.6838 46.0176 15.7838C63.0176 15.8838 76.0476 30.0738 75.7876 45.9638ZM21.5876 45.9138C21.5876 59.2138 32.3176 70.0238 45.5776 70.0938C58.6876 70.1538 69.7975 59.2138 69.7675 46.2638C69.7275 32.6638 59.0975 21.8938 45.6976 21.8938C32.3376 21.8938 21.5876 32.6138 21.5876 45.9138Z" fill="#333333"/>
<path d="M77.6473 71.8038C68.4573 83.4438 51.6373 90.2038 34.5673 85.2938C18.3873 80.6238 5.9873 66.6638 5.0573 48.1338C4.0173 27.3338 17.2873 12.5538 32.5073 7.27384C48.9173 1.58384 66.7073 6.91384 77.1073 19.4538C76.9673 19.7438 76.6973 19.6338 76.4773 19.6338C73.9073 19.6638 71.3273 19.6638 68.7573 19.7138C68.1373 19.7238 67.6773 19.5238 67.1973 19.1438C61.3873 14.5738 54.7973 12.0438 47.3873 11.7738C30.1573 11.1538 15.4773 22.8938 12.2473 39.8538C8.71729 58.4538 21.8073 77.0038 40.4773 79.7738C50.6673 81.2938 59.7873 78.7938 67.7973 72.3038C68.2573 71.9338 68.6973 71.7838 69.2673 71.7838C72.0273 71.8138 74.7773 71.8038 77.6473 71.8038Z" fill="#333333"/>
<path d="M77.0566 33.3637C79.3866 33.3637 81.5566 33.3837 83.7366 33.3537C84.3266 33.3437 84.4066 33.6937 84.5366 34.1037C85.9366 38.6637 86.4266 43.3237 86.1766 48.0737C86.0066 51.3137 85.4766 54.4937 84.6966 57.6437C84.4966 58.4337 84.0966 58.6437 83.3766 58.6337C81.3866 58.6137 79.3866 58.6237 77.2766 58.6237C80.3766 50.2237 80.3466 41.8437 77.0566 33.3637Z" fill="#333333"/>
<path d="M45.6665 25.6441C57.1465 25.6741 66.3965 35.2241 65.8965 46.8641C65.4365 57.5341 56.6865 66.4141 45.1165 66.2641C33.7565 66.1141 25.0465 56.5241 25.3065 45.2741C25.5665 34.4841 34.7365 25.5341 45.6665 25.6441ZM45.6365 60.1541C53.4065 60.1541 59.8565 53.7041 59.8665 45.9141C59.8764 38.1841 53.3765 31.7541 45.5665 31.7441C37.7065 31.7441 31.3865 38.1341 31.3765 46.0741C31.3765 53.8041 37.8165 60.1541 45.6365 60.1541Z" fill="#333333"/>
<path d="M45.6482 35.354C51.5282 35.364 56.2782 40.144 56.2382 45.994C56.1982 51.874 51.4582 56.564 45.5582 56.554C39.6782 56.544 34.9682 51.804 34.9982 45.914C35.0182 40.024 39.7382 35.344 45.6482 35.354Z" fill="#333333"/>
<path d="M147.497 53.1036V38.4136H141.977V53.0536H132.467V38.4136H127.377V54.1136C127.377 56.2736 127.957 58.3336 130.217 58.3336H139.377C141.877 58.2936 141.977 55.2136 141.977 55.2136C142.167 58.5736 144.277 58.3836 144.277 58.3836L147.587 58.3336H150.327C153.397 58.3336 154.957 57.0236 155.747 55.7236C156.547 54.4136 156.567 53.1036 156.567 53.1036H147.497Z" fill="#333333"/>
<path d="M177.156 53.1036V41.4436C177.156 38.5636 174.806 38.4136 174.806 38.4136H161.266V25.6436H155.746V58.3336H161.366L161.416 55.2136C161.416 55.2136 161.696 58.3336 164.296 58.3336H179.226C182.346 58.3336 184.236 56.9836 185.316 55.6536C186.376 54.3636 186.706 53.1036 186.706 53.1036H177.156ZM172.216 53.1036H161.416V43.4036H172.216V53.1036Z" fill="#333333"/>
<path d="M193.817 53.1538C193.817 53.1538 190.497 53.1538 190.497 50.6538V50.0338H206.587V42.6438C206.587 42.6438 206.347 38.3638 201.297 38.3638H189.057C189.057 38.3638 185.217 38.3638 185.217 43.1638V54.5938C185.217 54.9838 185.257 55.3438 185.317 55.6538C185.877 58.2938 188.487 58.2938 188.487 58.2938H208.167C210.537 58.2938 212.117 57.2138 213.127 56.0538C214.337 54.6738 214.747 53.1538 214.747 53.1538H193.817ZM190.497 44.6038C190.497 44.6038 190.697 42.8838 193.047 42.8838H199.427C201.107 42.8838 201.537 44.6038 201.537 44.6038L201.497 45.9538H190.497V44.6038Z" fill="#333333"/>
<path d="M218.817 53.1036V25.6436H213.107V55.8436C213.107 55.8436 213.107 55.9236 213.127 56.0536C213.177 56.6436 213.527 58.3336 215.457 58.3336H221.557C224.697 58.3336 226.407 56.9836 227.327 55.6636C228.217 54.3736 228.377 53.1036 228.377 53.1036H218.817Z" fill="#333333"/>
<path d="M235.817 53.1538C235.817 53.1538 232.497 53.1538 232.497 50.6538V50.0338H248.577V42.6438C248.577 42.6438 248.337 38.3638 243.297 38.3638H231.057C231.057 38.3638 227.217 38.3638 227.217 43.1638V54.5938C227.217 54.9938 227.257 55.3438 227.327 55.6638C227.887 58.2938 230.487 58.2938 230.487 58.2938H250.167C250.457 58.2938 250.727 58.2738 250.987 58.2438C255.497 57.7438 256.747 53.1538 256.747 53.1538H235.817ZM232.497 44.6038C232.497 44.6038 232.697 42.8838 235.047 42.8838H241.427C243.107 42.8838 243.537 44.6038 243.537 44.6038L243.497 45.9538H232.497V44.6038Z" fill="#333333"/>
<path d="M284.997 53.1034C284.997 53.1034 284.127 58.3334 278.567 58.3334H260.157C259.847 58.3334 259.547 58.3134 259.267 58.2834H257.707C255.457 58.2834 254.877 56.2234 254.877 54.0634V53.1034H270.937C271.717 52.5834 271.807 51.7734 271.807 51.7734V50.3934H258.987C258.987 50.3934 258.057 50.3934 257.197 49.8134C256.437 49.3134 255.727 48.3534 255.727 46.5834V42.6334C255.727 37.6934 259.567 37.6934 259.567 37.6934H271.807C276.847 37.6934 277.087 42.0934 277.087 42.0934V42.3334H263.547C262.187 42.3334 261.547 42.9334 261.257 43.4334C261.047 43.7934 261.007 44.1134 261.007 44.1134V45.4934H261.257V45.5534H274.787C274.787 45.5534 276.747 45.6734 277.087 47.8834V53.1034H284.997Z" fill="#333333"/>
<path d="M314.557 53.1034C314.557 53.1034 313.688 58.3334 308.128 58.3334H289.717C289.407 58.3334 289.107 58.3134 288.827 58.2834H287.267C285.017 58.2834 284.438 56.2234 284.438 54.0634V53.1034H300.237C300.297 53.0534 300.358 53.0134 300.418 52.9634C301.028 52.4534 301.098 51.7734 301.098 51.7734V50.3934H288.277C288.277 50.3934 287.347 50.3934 286.487 49.8134C285.727 49.3134 285.017 48.3534 285.017 46.5834V42.6334C285.017 40.0334 286.077 38.8034 287.087 38.2234C287.997 37.6934 288.857 37.6934 288.857 37.6934H301.098C302.188 37.6934 303.057 37.9034 303.747 38.2234C304.327 38.5034 304.788 38.8634 305.138 39.2534C306.288 40.5134 306.378 42.0934 306.378 42.0934V42.3334H292.837C291.477 42.3334 290.837 42.9334 290.547 43.4334C290.337 43.7934 290.297 44.1134 290.297 44.1134V45.4934H290.547V45.5534H304.077C304.077 45.5534 304.588 45.5834 305.138 45.9234C305.658 46.2234 306.208 46.8134 306.378 47.8834V53.1034H314.557Z" fill="#333333"/>
<path d="M118.567 53.2441C116.687 54.8841 114.237 55.8741 111.567 55.8841C105.727 55.8841 100.837 51.0241 100.837 45.2141C100.837 39.3741 105.657 34.5741 111.557 34.5641C113.947 34.5641 116.167 35.3541 117.957 36.6841H125.707C122.807 31.9241 117.577 28.7441 111.607 28.7441C102.507 28.7441 95.127 36.1241 95.127 45.2241C95.127 54.3241 102.507 61.7041 111.607 61.7041C117.797 61.7041 123.187 58.2941 125.997 53.2441H118.567Z" fill="#333333"/>
<path d="M201 85.4078H202.95V84.1982C203.638 85.0779 204.659 85.6277 205.83 85.6277C208.115 85.6277 209.805 83.9049 209.805 80.9908C209.805 78.1317 208.133 76.3356 205.83 76.3356C204.697 76.3356 203.656 76.8488 202.95 77.7835V73.1833H201V85.4078ZM202.95 82.7503V79.2497C203.378 78.5899 204.344 78.0401 205.254 78.0401C206.814 78.0401 207.799 79.268 207.799 80.9908C207.799 82.7136 206.814 83.9233 205.254 83.9233C204.344 83.9233 203.378 83.4101 202.95 82.7503Z" fill="#D90E0E"/>
<path d="M210.958 87.1489L210.661 88.8717C210.939 88.945 211.478 89 211.757 89C213.15 88.9817 214.227 88.5052 214.84 87.0023L219.168 76.5556H217.069L214.45 83.1901L211.831 76.5556H209.75L213.428 85.5178L212.983 86.5258C212.723 87.1123 212.314 87.2955 211.701 87.2955C211.478 87.2955 211.162 87.2406 210.958 87.1489Z" fill="#D90E0E"/>
<path d="M234.434 85.4078H236.607V73.1833H233.542L230.161 81.4124L226.799 73.1833H223.734V85.4078H225.907V76.1523L229.697 85.4078H230.626L234.434 76.1523V85.4078Z" fill="#D90E0E"/>
<path d="M242.495 85.6277C245.374 85.6277 247.12 83.52 247.12 80.9725C247.12 78.4433 245.374 76.3356 242.495 76.3356C239.653 76.3356 237.888 78.4433 237.888 80.9725C237.888 83.52 239.653 85.6277 242.495 85.6277ZM242.495 83.9233C240.842 83.9233 239.913 82.5487 239.913 80.9725C239.913 79.4147 240.842 78.0401 242.495 78.0401C244.167 78.0401 245.096 79.4147 245.096 80.9725C245.096 82.5487 244.167 83.9233 242.495 83.9233Z" fill="#D90E0E"/>
<path d="M254.667 85.4078H256.617V73.1833H254.667V77.7835C253.942 76.8488 252.902 76.3356 251.788 76.3356C249.466 76.3356 247.794 78.1317 247.794 80.9908C247.794 83.9049 249.484 85.6277 251.788 85.6277C252.939 85.6277 253.979 85.0779 254.667 84.1982V85.4078ZM254.667 82.7503C254.221 83.4101 253.274 83.9233 252.345 83.9233C250.803 83.9233 249.818 82.6953 249.818 80.9908C249.818 79.268 250.803 78.0401 252.345 78.0401C253.274 78.0401 254.221 78.5716 254.667 79.2314V82.7503Z" fill="#D90E0E"/>
<path d="M257.659 83.685C258.755 84.8396 260.408 85.6277 262.693 85.6277C266.036 85.6277 267.485 83.8866 267.485 81.8522C267.485 79.1947 265.052 78.5716 263.009 78.0584C261.541 77.6919 260.278 77.3803 260.278 76.4273C260.278 75.5109 261.095 74.9061 262.377 74.9061C263.677 74.9061 264.996 75.3459 265.962 76.299L267.207 74.7045C266.055 73.6048 264.495 73 262.563 73C259.851 73 258.049 74.5578 258.049 76.5739C258.049 79.1581 260.426 79.7446 262.451 80.2394C263.956 80.6243 265.275 80.9542 265.275 82.0538C265.275 82.8603 264.569 83.7216 262.786 83.7216C261.058 83.7216 259.721 82.9336 258.866 82.0355L257.659 83.685Z" fill="#D90E0E"/>
<path d="M276.936 88.7801V76.5556H274.985V77.7652C274.317 76.8855 273.276 76.3356 272.106 76.3356C269.784 76.3356 268.131 78.0584 268.131 80.9725C268.131 83.8866 269.784 85.6277 272.106 85.6277C273.239 85.6277 274.261 85.1329 274.985 84.1798V88.7801H276.936ZM274.985 82.732C274.558 83.3734 273.592 83.9233 272.682 83.9233C271.14 83.9233 270.156 82.6953 270.156 80.9725C270.156 79.268 271.14 78.0401 272.682 78.0401C273.592 78.0401 274.558 78.5716 274.985 79.2314V82.732Z" fill="#D90E0E"/>
<path d="M284.777 85.4078H286.727V76.5556H284.777V82.7686C284.312 83.3734 283.458 83.9233 282.473 83.9233C281.377 83.9233 280.671 83.5017 280.671 82.1271V76.5556H278.721V82.8236C278.721 84.638 279.668 85.6277 281.6 85.6277C283.012 85.6277 284.126 84.9496 284.777 84.2348V85.4078Z" fill="#D90E0E"/>
<path d="M293.997 85.4078H295.948V79.543C295.948 77.1604 294.202 76.3356 292.214 76.3356C290.784 76.3356 289.483 76.7938 288.443 77.7652L289.242 79.1031C290.022 78.3517 290.895 77.9851 291.898 77.9851C293.143 77.9851 293.997 78.6082 293.997 79.6346V80.9542C293.347 80.2027 292.307 79.8179 291.099 79.8179C289.632 79.8179 287.979 80.6609 287.979 82.6953C287.979 84.6564 289.632 85.6277 291.099 85.6277C292.288 85.6277 293.329 85.2062 293.997 84.4548V85.4078ZM293.997 83.3918C293.552 83.9966 292.716 84.3081 291.861 84.3081C290.765 84.3081 289.948 83.685 289.948 82.732C289.948 81.7606 290.765 81.1375 291.861 81.1375C292.716 81.1375 293.552 81.449 293.997 82.0538V83.3918Z" fill="#D90E0E"/>
<path d="M304.05 85.4078H306V73.1833H304.05V77.7835C303.325 76.8488 302.285 76.3356 301.17 76.3356C298.848 76.3356 297.176 78.1317 297.176 80.9908C297.176 83.9049 298.867 85.6277 301.17 85.6277C302.322 85.6277 303.362 85.0779 304.05 84.1982V85.4078ZM304.05 82.7503C303.604 83.4101 302.656 83.9233 301.728 83.9233C300.186 83.9233 299.201 82.6953 299.201 80.9908C299.201 79.268 300.186 78.0401 301.728 78.0401C302.656 78.0401 303.604 78.5716 304.05 79.2314V82.7503Z" fill="#D90E0E"/>
</svg>

)
