/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsx jsx **/
import { jsx } from "theme-ui"

export default props => (
  <div sx={{
    mb: 2,
  }}>
      <h1 sx={{
        fontSize: 1,
        fontFamily: 'body',
        fontStyle: 'italic',
        lineHeight: 1,
      }}>
      {props.name}
      </h1>
  </div>
)